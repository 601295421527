const dayjs = require('dayjs')

export const filterTimestamp = (val, format = 'YYYY-MM-DD') => {
  if (!isNull(val)) {
    val = parseInt(val)
    return dayjs(val).format(format)
  } else {
    return '--'
  }
}


/**
 * 获取时间差
 * @param start
 * @param end
 */
function filterCostTimeString(timeCost, level) {
  // let timeCost = end - start; //时间差的毫秒数
  let days = Math.floor(timeCost / (24 * 3600 * 1000)); // 计算出相差天数
  //计算出小时数
  let leave1 = timeCost % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
  let hours = Math.floor(leave1 / (3600 * 1000));
  //计算出相差分钟数
  let leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
  let minutes = Math.floor(leave2 / (60 * 1000));
  //计算出相差秒数
  let leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
  let seconds = Math.round(leave3 / 1000);
  if (level === 1) {
    return seconds + "秒";
  }
  if (level === 2) {
    return minutes + "分钟" + seconds + "秒";
  }
  if (level === 3) {
    return hours + "小时" + minutes + "分钟" + seconds + "秒";
  }
  if (level === 4) {
    return days + "天" + hours + "小时" + minutes + "分钟" + seconds + "秒";
  }
  return minutes + "分钟" + seconds + "秒";
}


export const isNull = date => {
  if (!date) return true
  if (JSON.stringify(date) === '{}') return true
  if (JSON.stringify(date) === '[]') return true
  return false
}

export default app => {
  app.config.globalProperties.$filters = {
    filterTimestamp,
    filterCostTimeString
  }
}

export const customFillColor = (level) => {
  switch (level) {
    case 1:
      return "#95ff95";
    case 2:
      return "#00ff00";
    case -1:
      return "#ff6565";
    case -2:
      return "#ff0000";
    default:
      return "#b7f5b2";
  }
}
