import {createRouter, createWebHashHistory} from 'vue-router'

const routes = [
  // {
  //     path: '/',
  //     name: 'Home',
  //     component: () => import('../views/Home')
  // },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login')
  },
  {
    path: '/',
    name: '/',
    component: () => import('../layout'),
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/dashboard/index.vue')
      },
      {
        path: 'threecommasAssistant',
        name: 'threecommasAssistant',
        component: () => import('@/views/threecommasAssistant/index.vue')
      },
      {
        path: 'testTable',
        name: 'TestTable',
        component: () => import('@/views/testTable/index.vue')
      },
      {
        path: 'indicatorAnalysis',
        name: 'IndicatorAnalysis',
        component: () => import('@/views/indicatorAnalysis/index.vue')
      },
      {
        path: 'settings',
        name: 'settings',
        component: () => import('@/views/settings/index.vue')
      },
      {
        path: 'tradeview',
        name: 'tradeview',
        component: () => import('@/views/tradeview/index.vue')
        // component: () => import('@/views/tradeview/index_bak.vue')
      },
      {
        path: 'toolbox',
        name: 'Toolbox',
        component: () => import('@/views/toolbox/index.vue')
      },
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
