export default {
  namespaced: true,
  state: () => ({
    DCAParams: localStorage.getItem('DCAParams') ? JSON.parse(localStorage.getItem('DCAParams')) : {},
    DCAParamsList: localStorage.getItem('DCAParamsList') ? JSON.parse(localStorage.getItem('DCAParamsList')) : [],
  }),
  mutations: {
    setDCAParams(state, newVal) {
      state.DCAParams = newVal
      localStorage.setItem('DCAParams', JSON.stringify(newVal))
    },
    setDCAParamsList(state, newVal) {
      state.DCAParamsList = newVal
      localStorage.setItem('DCAParamsList', JSON.stringify(newVal))
    },
  },
  actions: {}
}
