<template>
  <router-view/>
</template>

<script setup>
import {onBeforeMount, ref, onMounted} from "vue";
import {destroy} from './landing'


onBeforeMount(() => {
  destroy()
})


</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  //color: #2c3e50;
  //text-align: center;
}
</style>
