import {login as loginApi} from "../../api/user";
import {message} from "ant-design-vue";
import router from "@/router";
import {setTokenTime} from '@/utils/auth'

const registeredAccount = {
  'admin': {
    username: 'admin',
    password: '123456',
  }
}

export default {
  namespaced: true,
  state: () => ({
    token: localStorage.getItem('token') || '',
    collapsed: true,
    lang: localStorage.getItem('lang') || 'zh',
    networkChannel: localStorage.getItem('net1') || 'net1',
  }),
  mutations: {
    setToken(state, newVal) {
      state.token = newVal
      localStorage.setItem('token', newVal)
    },
    changeCollapse(state) {
      state.collapsed = !state.collapsed
    },
    changeLang(state, val) {
      state.lang = val
      localStorage.setItem('lang', val)
    },
    changeNetworkChannel(state, val) {
      state.lang = val
      localStorage.setItem('net change', val)
    },
  },
  actions: {
    login({commit}, userInfo) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          // loginApi(userInfo).then(res => {
          //     console.log('login res', res)
          // }).catch(e => {
          //     console.log('login err', e)
          // })
          if (!registeredAccount[userInfo.username]) {
            message.error(`账户不存在`);
            reject()
          } else if (registeredAccount[userInfo.username].password != userInfo.password) {
            message.error(`密码错误`);
            reject()
          } else {
            message.success(`欢迎回来，${userInfo.username}`);
            commit('setToken', '123456')
            setTokenTime()
            router.replace('/')
            resolve()
          }
        }, 500)
      })
    },
    logout({commit}) {
      commit('setToken', '')
      localStorage.clear()
      router.replace('/login')
    }
  }
}
