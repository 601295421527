export default {
    menus: {
        '/': 'home',
        home: 'home',
        settings: 'settings',
    },
    login: {
        title: 'Login',
        btnTitle: 'login',
        username:'username',
        password:'password',
        rememberMe:'Remember me!'
    }
}