import router from './index'
import store from '@/store'

const whileList = ['/login']

// console.log('加载路由守卫')
router.beforeEach(((to, from, next) => {
    if (store.getters.token) {
        // console.log('有token', store.getters.token)
        if (to.path === '/login') {
            next('/')
        } else {
            next()
        }
    } else {
        // console.log('无token')
        if (whileList.includes(to.path)) {
            next()
        } else {
            next('/login')
        }
    }
}))