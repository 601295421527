export default {
    // common
    token: (state) => state.app.token,
    collapsed: (state) => state.app.collapsed,
    networkChannel: (state) => state.app.networkChannel,

    // traderview
    tradeviewOptions: (state) => state.tradeview.options,

    // toolbox
    DCAParams: (state) => state.toolbox.DCAParams,
    DCAParamsList: (state) => state.toolbox.DCAParamsList,

}
