import axios from 'axios'
import store from '@/store'
import {diffTokenTime} from "./auth";
import {message} from "ant-design-vue";
import {API_URL, API_URL_LOCAL} from "./constant";
import os from "os";

// const currentBaseApiUrl = os.platform() === 'win32' ? API_URL_LOCAL : API_URL
// const currentBaseApiUrl = process.env.NODE_ENV === 'development' ? API_URL_LOCAL : API_URL
const currentBaseApiUrl = API_URL
// console.log(os.platform(),currentBaseApiUrl)
const service = axios.create({
  baseURL: currentBaseApiUrl,
  timeout: 5000
})


service.interceptors.request.use(
  config => {
    // if (localStorage.getItem('token')) {
    //     if (diffTokenTime()) {
    //         store.dispatch('app/logout')
    //         return Promise.reject(new Error('token本地失效了'))
    //     }
    // }
    // config.headers.token = localStorage.getItem('token')
    // config.headers.token = localStorage.getItem('token')

    // console.log('store =>',store)
    // console.log('请求 token',store.getters.token)


    config.headers.token = store.getters.token
    return config
  }
)


service.interceptors.response.use(
  response => {
    // return response
    return response.data
  }, error => {
    console.log('请求错误', error)
    // message.error(error.toString())
  }
)

export default service
