export default {
    menus: {
        '/': '首页',
        home: '首页',
        settings: '设置',
    },
    login: {
        title: '登录',
        btnTitle: '登录',
        username:'用户名',
        password:'密码',
        rememberMe:'记住我！'
    },
    toolbox:{
      title:'工具箱',
      DCACalc:{
        baseOrderAmount:'基础订单数量',
        safetyOrderAmount:'基础安全订单数量',
        leverage:'杠杆',
        maxSafetyOrderNumber:'最大安全订单数量',
        priceDeviation:'回撤 %',
        safetyOrderVolumeScale:'安全订单大小倍率',
        safetyOrderStepScale:'安全订单间隔倍率',
        targetProfit:'止盈 %',
        trailingDeviation:'追踪止盈',
        lossPercentage:'止损 %',
        summary:'描述',
      }

    }
}
