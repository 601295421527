import {TOKEN_TIME, TOKEN_TIME_VALUE} from "./constant";


export const setTokenTime = () => {
    localStorage.setItem(TOKEN_TIME, Date.now())
}


export const getTokenTime = () => {
    localStorage.getItem(TOKEN_TIME)
}


export const diffTokenTime = () => {
    const curTime = Date.now()
    const tokenTime = getTokenTime() || 0
    return curTime - tokenTime > TOKEN_TIME_VALUE
}