import {createStore} from 'vuex'
import app from './modules/app'
import tradeview from './modules/tradeview'
import toolbox from './modules/toolbox'
import getters from "./getters";

export default createStore({
    // state: {
    // },
    // mutations: {
    // },
    // actions: {
    // },
    modules: {
        app,
        tradeview,
        toolbox
    },
    getters
})
