export default {
  namespaced: true,
  state: () => ({
    options: localStorage.getItem('trade_options') ? JSON.parse(localStorage.getItem('trade_options')) : {},
  }),
  mutations: {
    setOptions(state, newVal) {
      state.options = newVal
      localStorage.setItem('trade_options', JSON.stringify(newVal))
    },
  },
  actions: {}
}
