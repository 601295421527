import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/router/permission'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import i18n from "./i18n";
import filters from './utils/filters'

console.log('当前环境', process.env.NODE_ENV)
if (process.env.NODE_ENV === 'development') {
    require('./mock/index.js')
}
// require('./mock/index.js')


const app = createApp(App)

filters(app)

app.use(store)
    .use(router)
    .use(Antd)
    .use(i18n)
    .mount('#app')
